import React from "react";
import styles from "../../Shared/ToggleButton/toggleButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

const CreateTask = ({ vehicleId }) => {
  const newLinkTo = {
    pathname: "/tasks/new",
    vehicleId: vehicleId,
  };
  return (
    <Link
      className={styles.toggleButtonContainer}
      style={{ marginRight: "12px" }}
      to={newLinkTo}
    >
      <FontAwesomeIcon icon={faPlus} />
    </Link>
  );
};

export default CreateTask;
